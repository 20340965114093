<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <span class="card-title h4">All Specialities</span>
                        <button class="float-right btn btn-primary" v-b-modal.addSpecialityModal
                                @click="addSpecialityModal">Add
                        </button>
                        <p class="card-title-desc"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-12 col-md-6">
                                <!--                                <div id="tickets-table_length" class="dataTables_length">-->
                                <!--                                    <label class="d-inline-flex align-items-center">-->
                                <!--                                        Show-->
                                <!--                                        <b-form-select v-model="perPage" size="sm"-->
                                <!--                                                       :options="pageOptions"></b-form-select>-->
                                <!--                                        entries-->
                                <!--                                    </label>-->
                                <!--                                </div>-->
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="specialities"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                            >
                                <!--              <template v-slot:cell(status)="speciality">-->
                                <!--                  <button :class="speciality.item.status == '1' ? btn_color1:btn_color2" @click="toggleState(speciality)">-->
                                <!--                    <div v-if="speciality.item.status">Active</div>-->
                                <!--                    <div v-else>In Active</div>-->
                                <!--                  </button>&nbsp;-->
                                <!--              </template>-->
                                <template v-slot:cell(created_at)="{item}">
                                    <span>{{ new Date(item.created_at).toLocaleString() }}</span>
                                </template>
                                <template v-slot:cell(actions)="speciality">
                                    <button class="btn btn-success btn-sm" @click="getSubSpeciality(speciality)">Sub
                                        Speciality
                                    </button>&nbsp;
                                    <button class="btn btn-primary btn-sm" v-b-modal.editSpecialityModal
                                            @click="edit_speciality(speciality)">Edit
                                    </button>&nbsp;
                                    <button class="btn btn-danger btn-sm" @click="delete_speciality(speciality)">
                                        Delete
                                    </button>&nbsp;
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows"
                                                      :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AddSpecialitiesModal v-if="add_form" :modal_placeholder="'Enter Speciality Title'"/>
        <EditSpecialitiesModal v-if="edit_form" :selected_id="getId" :selected_title="getTitle"/>
    </Layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import AddSpecialitiesModal from "./add_settings/specialities";
    import EditSpecialitiesModal from "./edit_settings/specialities";
    import {specialityMethods} from "@/state/helpers";
    import moment from 'moment';

    /**
     * Advanced table component
     */
    export default {
        page: {
            title: "Specialities",
            meta: [{name: "description", content: appConfig.description}]
        },
        components: {Layout, PageHeader, AddSpecialitiesModal, EditSpecialitiesModal,},
        data() {
            return {
                title: "Specialities",
                items: [
                    {
                        text: "Settings",
                    },
                    {
                        text: "Services",
                    },
                    {
                        text: "Fo Services",
                    },
                    {
                        text: this.$route.params.fo_service_title
                    }
                ],
                msg: "",
                add_form: false,
                edit_form: false,
                getId: 0,
                getTitle: "",
                btn_color1: 'btn btn-success btn-sm',
                btn_color2: 'btn btn-danger btn-sm',
                employers: [],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "id",
                sortDesc: true,
                fields:
                    [
                        {key: "title", label: 'Title', sortable: true},
                        // {key: "status", label: 'Status', sortable: true},
                        {key: "created_at", label: 'Created At', sortable: true, dataType: "Date"},
                        {key: "actions", label: 'Actions'},
                    ]
            };
        },
        computed: {
            rows() {
                return this.$store.state.speciality.specialities.length;
            },
            specialities() {
                return this.$store.state.speciality.specialities
            }
        }, methods: {
            ...specialityMethods,

            // get all services
            getSpecialities() {
                let obj = {id: this.$route.params.fo_service_id};
                this.getAllSpecialities(obj).then().catch(error => {
                    this.unauthorizedError(error);
                    if (error.data.status === 400) {
                        this.errorMessage(error.data.message)
                    }
                });
            },
            errorMessage(error) {
                this.msg = error ? error : "Something went wrong!!!"
                this.$swal({
                    icon: 'error',
                    toast: true,
                    text: this.msg,
                    showCloseButton: false,
                    showConfirmButton: false,
                    timer: 3000
                });
            },

            // delete service
            delete_speciality(speciality) {
                this.$swal({
                    icon: 'warning',
                    text: 'Are you sure to delete',
                    showCancelButton: true,
                    toast: true,
                    confirmButtonColor: 'green',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }).then(result => {
                    if (result.value) {
                        this.msg = '';
                        this.deleteSpeciality({id: speciality.item.id}).then(res => {
                            if (res.status === 200) {
                                this.msg = res.message ? res.message : "Service deleted successfully"
                                this.$swal({
                                    icon: 'success',
                                    text: this.msg,
                                    toast: true,
                                    showCloseButton: false,
                                    showConfirmButton: false,
                                    timer: 3000
                                }).then(
                                    this.getSpecialities(),
                                );
                            }
                        }).catch(error => {
                            this.msg = error ? error : "Something went wrong!!!"
                            this.$swal({
                                icon: 'error',
                                text: this.msg,
                                showCloseButton: true,
                            });
                            this.unauthorizedError(error);
                        }).then(
                            this.getSpecialities(),
                        );
                    }
                });
            },
            unauthorizedError(err) {
                if (err.data.status === 401) {
                    return this.$router.push('/logout');
                }
            },

            // set the date format
            format_date(value) {
                return moment(value).format("DD MM YYYY")
            },

            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

            // render speciality page
            getSubSpeciality(speciality) {
                this.$router.push({
                    name: 'subspeciality',
                    params: {speciality_id: speciality.item.id, speciality_title: speciality.item.title}
                })
            },
            // onclick to show data model of edit service
            edit_speciality(speciality) {
                this.getId = speciality.item.id;
                this.getTitle = speciality.item.title;
                this.edit_form = true;
            },

            // onclick to show data model of add service
            addSpecialityModal() {
                this.add_form = true;
            },

            // toggleState(speciality){
            //     speciality.item.status=!speciality.item.status;
            // }
        },
        mounted() {
            if (!this.$route.params.fo_service_id) {
                this.$router.push('/services');
                return;
            }
            this.getSpecialities();
        },
        created() {
        },
    };
</script>
